
    @import "config.scss";
 
.wrapper {
  position: relative;
  .container {
    border-radius: 7px;
    position: relative;

    button {
      padding: 1.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4.5rem;
      height: 1rem;
      border-radius: var(--br-soft);
      cursor: pointer;
      background-color: transparent;
      border: 1px solid var(--color-white);
    }

    .buttonCity {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .checked {
    button {
      border: 1px solid var(--color-primary) !important;
    }
  }

  .noCount {
    button {
      cursor: not-allowed;
      border-color: var(--color-disable-size);
      color: rgb(255, 0, 0);
    }

    .dash {
      height: 2px;
      width: 105%;
      background-color: var(--color-disable-size);
      position: absolute;
      transform: rotate(28deg);
    }
  }
}

.icon svg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
